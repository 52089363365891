import { DOCS_BASE_URL } from 'const/app-config'
import { useDocsPanel } from 'context/docs-provider'
import { XIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Button } from './catalyst/button'

export function DocsPanel() {
  const { setVisible, path } = useDocsPanel()
  const [iframeUrl, setIframeUrl] = useState(`${DOCS_BASE_URL}${path}`)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const newUrl = `${DOCS_BASE_URL}${path}`
    if (iframeRef.current && iframeRef.current.src !== newUrl) {
      setIframeUrl(newUrl)
    }
  }, [path])

  return (
    <>
      <div className="absolute right-4 top-[18px] z-30 rounded-lg bg-white dark:bg-zinc-950">
        <Button outline onClick={() => setVisible(false)} className="dark:bg-zinc-950">
          <XIcon size={16} />
          Close
        </Button>
      </div>

      <iframe ref={iframeRef} src={iframeUrl} className="h-full w-full rounded-2xl" title="Documentation" />
    </>
  )
}
