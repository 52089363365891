import { useAsyncData } from 'hooks/use-async-data'
import { useCallback, useEffect } from 'react'
import { create } from 'zustand'
import { listIndexesApi } from '../services/index.services'
import { Index } from '../types'

type State = {
  indexes: Index[]
  setIndexes: (indexes: Index[]) => void
}

const useIndexesStore = create<State>((set) => ({
  indexes: [],
  setIndexes: (indexes: any[]) => set({ indexes }),
}))

export function useIndexStore() {
  const { indexes, setIndexes } = useIndexesStore()
  const { loading, fetchData } = useAsyncData(listIndexesApi, {
    onDataFetched: (fetchedIndexes) => setIndexes(fetchedIndexes?.data?.indexes),
    showErrorMessage: true,
  })

  const getIndexByName = useCallback(
    (name: string) => {
      return indexes.find((index) => index.name === name)
    },
    [indexes]
  )

  // Check if indexes are already fetched, if not, trigger fetch
  // or if force is true, fetch indexes
  const fetchIndexes = useCallback(
    (force = false) => {
      if (force || !indexes?.length) {
        fetchData()
      }
    },
    [indexes, fetchData]
  )

  useEffect(() => {
    fetchIndexes()
  }, [])

  return { indexes, loading, fetchIndexes, getIndexByName }
}
