export const RouteNames = {
  Dashboard: '/',
  Query: '/query',
  Settings: '/settings',

  // Indexes
  Indexes: '/',
  CreateIndex: '/index/create',
  IndexDetail: {
    get: (indexName: string) => `/index/${indexName}`,
    name: '/index/:indexName',
  },
  IndexDetailConsole: {
    get: (indexName: string) => `/index/${indexName}/console`,
    name: 'console',
  },
  IndexDetailAccessDB: {
    get: (indexName: string) => `/index/${indexName}/access-db`,
    name: 'access-db',
  },
  IndexDetailMetrics: {
    get: (indexName: string) => `/index/${indexName}/metrics`,
    name: 'metrics',
  },
  IndexDetailAPIKeys: {
    get: (indexName: string) => `/index/${indexName}/api-keys`,
    name: 'api-keys',
  },
  IndexDetailConfiguration: {
    get: (indexName: string) => `/index/${indexName}/config`,
    name: 'config',
  },
  IndexDetailLogs: {
    get: (indexName: string) => `/index/${indexName}/logs`,
    name: 'logs',
  },
  IndexDetailSettings: {
    get: (indexName: string) => `/index/${indexName}/settings`,
    name: 'settings',
  },

  // Auth
  Login: '/login',
  Register: '/register',
  ResetPassword: '/reset-password',
  Verification: '/verification',
}

export const AUTH_ROUTES = [RouteNames.Login, RouteNames.Register, RouteNames.ResetPassword, RouteNames.Verification]
