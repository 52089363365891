import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { API_BASE_URL } from 'const/app-config'
import { useAuthStore } from 'modules/auth'

export interface ApiResponse<T> {
  data: T
  status: number
}

export interface ApiError {
  message: string
  status: number
}

/**
 * @param url {String}
 * @param method {Method}
 * @param data T
 * @param useAuthKey {Boolean}
 * @returns {Promise<ApiResponse<T>>}
 */
export async function apiCall<T, K>(
  url: string,
  method: Method = 'GET',
  data?: T,
  useAuthKey = true
): Promise<ApiResponse<K>> {
  const config: AxiosRequestConfig<T> = {
    baseURL: API_BASE_URL,
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  }

  if (useAuthKey) {
    const apiKey = useAuthStore?.getState()?.apiKey
    config.headers!['x-api-key'] = apiKey
  }

  try {
    const response: AxiosResponse<K> = await axios(config)
    return { data: response.data, status: response.status }
  } catch (error: any) {
    const status = error?.response?.status || 500
    const message = error?.response?.data?.message || 'An unexpected error occurred'
    throw { message, status } as ApiError
  }
}
