import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { CreateIndexFormSchema } from '../schema'

import { Button } from 'components/catalyst/button'
import { Input } from 'components/catalyst/input'
import { useNotificationStore } from 'components/common'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createIndexApi } from '../services/index.services'
import { useIndexStore } from '../store'

const formSchema = CreateIndexFormSchema

const formFields = [
  { name: 'indexName', label: 'Index Name', autoFocus: true },
  { name: 'similarityFunction', label: 'Similarity Function' },
  { name: 'cloud', label: 'Cloud' },
  { name: 'region', label: 'Region' },
]

export function CreateIndexForm() {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const { fetchIndexes } = useIndexStore()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      indexName: '',
      similarityFunction: 'cosine',
      cloud: 'gcp',
      region: 'us-east1',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setLoading(true)
      setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      const response = await createIndexApi(values)

      setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
      setTimeout(() => {
        fetchIndexes(true)
        navigate(RouteNames.IndexDetail.get(response.data.indexName))
      }, 750)
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function renderForm() {
    return formFields.map((formField) => (
      <FormField
        key={formField.name}
        control={form.control}
        name={formField.name as any}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{formField.label}</FormLabel>

            <FormControl>
              <Input disabled={loading} {...field} autoFocus={formField.autoFocus} />
            </FormControl>

            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    ))
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 sm:max-w-md">
        {renderForm()}

        <div className="flex justify-end">
          <Button color="amber" type="submit" loading={loading} disabled={loading}>
            Create Index
          </Button>
        </div>
      </form>
    </Form>
  )
}
