import { apiCall } from 'lib/api-client'
import {
  CreateIndexRequest,
  CreateIndexResponse,
  IndexVectorRequest,
  IndexVectorResponse,
  ListIndexesRequest,
  ListIndexesResponse,
  QueryIndexRequest,
  QueryIndexResponse,
} from '../types'

export function createIndexApi(values: CreateIndexRequest) {
  return apiCall<CreateIndexRequest, CreateIndexResponse>('/indexes', 'POST', values)
}

export function queryIndexApi(values: QueryIndexRequest) {
  return apiCall<QueryIndexRequest, QueryIndexResponse>('/query', 'POST', values)
}

export function indexVectorApi(values: IndexVectorRequest) {
  return apiCall<IndexVectorRequest, IndexVectorResponse>('/index', 'POST', values)
}

export function listIndexesApi() {
  return apiCall<ListIndexesRequest, ListIndexesResponse>('/list-indexes', 'POST')
}
