import { create } from 'zustand'

type Options = {
  dismissable?: boolean
  title?: string
  type: 'success' | 'error' | 'warning' | 'info' | 'loading'
  actionBtns?: { label: string; onClick: () => void }[]
}

type State = {
  type: Options['type']
  message: string
  title?: string
  visible: boolean
  dismissable: boolean
  actionBtns?: Options['actionBtns']
  setMessage: (message: string, options?: Options) => void
  setVisible: (value: boolean) => void
  reset: () => void
}

const useNotificationStore = create<State>((set) => ({
  type: 'info',
  message: '',
  visible: false,
  dismissable: true,
  actionBtns: [],

  setMessage: (message, options?: Options) => set({ message, ...options, visible: message?.length > 0 }),
  setVisible: (visible) => set({ visible }),

  reset: () => set({ type: 'info', message: '', visible: false, title: '', dismissable: true, actionBtns: [] }),
}))

export default useNotificationStore
