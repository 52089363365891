import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { IndexItemSkeleton } from 'components/skeletons'
import { RouteNames } from 'const/navigation'
import { useDocsPanel } from 'context/docs-provider'
import { useElementSize } from 'hooks/use-element-size'
import { cn } from 'lib/utils'
import { FileCode2 } from 'lucide-react'
import { MOCK_INDEX } from 'mocks'
import { Link } from 'react-router-dom'
import { IndexItem } from '../components/index-item'
import { useIndexStore } from '../store'

function IndexesPage() {
  const { openDocsPanel } = useDocsPanel()
  const { width: mainLayoutWidth } = useElementSize('#application-main')
  const { indexes, loading } = useIndexStore()

  const renderIndexes = () => {
    if (loading) {
      return new Array(6).fill(MOCK_INDEX).map((index, i) => <IndexItemSkeleton key={`IndexItemSkeleton-${i}`} />)
    }

    return indexes.map((index, i) => <IndexItem index={index} key={i} />)
  }

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  const renderEmptyState = () => {
    if (loading || indexes.length > 0) return null

    return (
      <Link
        to={RouteNames.CreateIndex}
        type="button"
        className="relative block w-full rounded-lg border-2 border-dashed border-neutral-300 p-12 text-center hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-500"
      >
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 48 48"
          aria-hidden="true"
          className="mx-auto h-12 w-12 dark:text-neutral-300"
        >
          <path
            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="mt-4 block text-sm font-medium text-neutral-700 dark:text-neutral-500">
          You don't have any indexes yet
        </span>
        <span className="mt-2 block text-sm font-medium text-neutral-700 dark:text-neutral-500">Create a new one</span>
      </Link>
    )
  }

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
            <Button className="max-sm:w-full" color="amber" href={RouteNames.CreateIndex}>
              Create New Index
            </Button>
          </>
        }
        description="An index organizes and holds multiple vectors in Vectroid."
      >
        Indexes
      </Heading>

      <div
        className={cn('grid grid-cols-1 gap-6', {
          'grid-cols-1': mainLayoutWidth! < 760,
          'grid-cols-2': mainLayoutWidth! > 760,
          'grid-cols-3': mainLayoutWidth! >= 1000,
        })}
      >
        {renderIndexes()}
      </div>

      {renderEmptyState()}
    </>
  )
}

export default IndexesPage
