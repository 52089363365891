import { cn } from 'lib/utils'
import React from 'react'

interface SpinnerProps {
  className?: string
  color?: string
}

const Spinner: React.FC<SpinnerProps> = ({ className = 'w-10 h-10', color = 'border-t-amber-900' }) => {
  return (
    <div className={'flex w-full items-center justify-center'}>
      <div
        className={cn('animate-spin rounded-full border-2 border-x-transparent border-b-transparent', color, className)}
      />
    </div>
  )
}

export default Spinner
