import { Banner } from 'components/banner'

import { useEffect } from 'react'
import useNotificationStore from './store'

export { useNotificationStore }

export function NotificationBanner() {
  const { type, message, title, visible, dismissable, actionBtns, reset } = useNotificationStore()

  useEffect(() => {
    if (!visible) {
      reset()
    }
  }, [visible, reset])

  useEffect(() => {
    if (type === 'success' && visible) {
      const timer = setTimeout(() => {
        reset()
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [type, visible, reset])

  if (!visible) return null

  return (
    <Banner
      type={type}
      title={title}
      message={message}
      actionBtns={actionBtns}
      onDismiss={reset}
      dismissable={dismissable}
    />
  )
}
