import { Button } from 'components/catalyst/button'
import Spinner from 'components/spinner'
import { cn } from 'lib/utils'
import { BadgeAlert, CircleCheck, OctagonAlert } from 'lucide-react'

type Props = {
  type: 'success' | 'error' | 'warning' | 'info' | 'loading'
  title?: string
  message: string
  actionBtns?: { label: string; onClick: () => void }[]
  onDismiss?: () => void
  dismissable?: boolean
}

export function Banner({ type, title, message, actionBtns, onDismiss, dismissable = false }: Props) {
  const alertStyle = {
    success: 'dark:bg-green-900 bg-green-700',
    error: 'dark:bg-red-800 bg-red-600',
    warning: 'bg-yellow-700',
    info: 'dark:bg-sky-800 bg-sky-600',
    loading: 'dark:bg-sky-800 bg-sky-600',
  }[type]

  const alertIcon = {
    success: <CircleCheck aria-hidden="true" className="h-6 w-6 text-white" />,
    error: <BadgeAlert aria-hidden="true" className="h-6 w-6 text-white" />,
    warning: <OctagonAlert aria-hidden="true" className="h-6 w-6 text-white" />,
    info: <OctagonAlert aria-hidden="true" className="h-6 w-6 text-white" />,
    loading: <Spinner className="h-6 w-6 border-t-white" />,
  }[type]

  const bannerTitle =
    title ||
    {
      success: 'Success',
      error: 'Error',
      warning: 'Warning',
      info: 'Info',
      loading: 'Loading',
    }[type]

  const renderActionBtns = () => {
    return actionBtns?.map((btn, i) => (
      <Button key={i} plain onClick={btn.onClick} className="text-white">
        {btn.label}
      </Button>
    ))
  }

  return (
    <div className={cn('mb-6 rounded-md p-4', alertStyle)}>
      <div className="flex">
        <div className="flex-shrink-0">{alertIcon}</div>
        <div className="ml-3 flex-1">
          <h3 className="text-sm font-medium text-white">{bannerTitle}</h3>
          <div className="mt-1 text-sm text-white/80">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
        {renderActionBtns()}
        {dismissable && (
          <Button plain onClick={() => onDismiss?.()} className="text-white">
            Dismiss
          </Button>
        )}
      </div>
    </div>
  )
}
